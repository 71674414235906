// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Web__Utils from "../utils/Web__Utils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptNext__Head from "../../../../packages/rescript-next/src/head/RescriptNext__Head.bs.js";
import * as App__FatalErrorBoundary from "../../../../packages/app/src/error/boundary/App__FatalErrorBoundary.bs.js";

function Web__404(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(RescriptNext__Head.make, {
                      children: [
                        JsxRuntime.jsx("title", {
                              children: Web__Utils.makePageTitleElement("Page not found")
                            }),
                        JsxRuntime.jsx("meta", {
                              content: "The official Decidr app",
                              name: "description"
                            }),
                        JsxRuntime.jsx("link", {
                              href: "/favicon.ico",
                              rel: "icon"
                            })
                      ]
                    }),
                JsxRuntime.jsx(App__FatalErrorBoundary.make, {
                      children: JsxRuntime.jsx(ReactNative.View, {
                            style: {
                              alignItems: "center",
                              flex: 1,
                              justifyContent: "center",
                              minHeight: "90vh"
                            },
                            children: Caml_option.some(JsxRuntime.jsx(ReactNative.Text, {
                                      children: "Page not found"
                                    }))
                          })
                    })
              ]
            });
}

var make = Web__404;

var $$default = Web__404;

export {
  make ,
  $$default as default,
}
/* Web__Utils Not a pure module */
