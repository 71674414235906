/* TypeScript file generated from Web__404.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as Web__404JS from './Web__404.bs.js';

export type props = {};

export const $$default: React.ComponentType<{}> = Web__404JS.default as any;

export default $$default;
